import React from "react";
import styled from "styled-components";
import { media, Color } from "../../utils/style";
import VisibilitySensor from "react-visibility-sensor";

const CircleElement = styled.div.attrs({ className: "ajs_circle_new" })`
    position: absolute;
    width: 770px;
    height: 770px;
    background-color: ${Color.ritualYellow};
    border-radius: 50%;
    opacity: 0;
    z-index: 1;
    transition: left 2s, right 2s, opacity 2s;

    &.obscured {
      background-color: rgba(255, 214, 0, 0.56);
    }

    &.lighter {
      background-color: rgba(255, 214, 0, 0.30);
    }

    &.left {
      left: -1540px;
      opacity: 0;
    }

    &.right {
      right: -1540px;
      opacity: 0;
    }

    &.top {
      top: -385px;
      opacity: 0;
    }

    &.topTimeline {
      top: -100px;
      opacity: 0;
    }

    &.featuredArticles {
      opacity: 0;
      z-index: 1;
    }

    &.experts {
      opacity: 0.56 !important;
      z-index: 0;
    }

    &.bottom {
      bottom: -385px;
      opacity: 0;
    }

    &.centerY {
      top: calc(50% - 385px);
      opacity: 0;
    }

    &.active {
      &.left {
        left: -385px;
        opacity: 1;
      }

      &.right {
        right: -385px;
        opacity: 1;
      }

      &.experts {
        opacity: 0.56 !important;
      }
    }

    ${media.notDesktop`
      width: 614px;
      height: 614px;

      &.left {
        left: -614px;
      }

      &.right {
        right: -614px;
      }

      &.top {
        top: -307px;
      }

      &.bottom {
        bottom: -307px;
      }

      &.centerY {
        top: calc(50% - 307px);
      }

    &.active {
      &.left {
        left: -307px;
        opacity: 1;
      }

      &.right {
        right: -307px;
        opacity: 1;
      }

      &.experts {
        opacity: 0.56;
      }
    }
    `}

    ${media.tablet`
      width: 460px;
      height: 460px;

      &.left {
        left: -460px;
      }

      &.right {
        right: -460px;
      }

      &.top {
        top: -230px;
      }

      &.bottom {
        bottom: -230px;
      }

      &.centerY {
        top: calc(50% - 230px);
      }
      &.active {
        &.left {
          left: -230px;
          opacity: 1;
        }

        &.right {
          right: -230px;
          opacity: 1;
        }

        &.experts {
          opacity: 0.56 !important;
        }
      }
    `}

    ${media.mobile`
      width: 280px;
      height: 280px;

      &.left {
        left: -280px;
      }

      &.right {
        right: -280px;
      }

      &.top {
        top: -140px;
      }

      &.topTimeline {
        top: -140px;
        z-index: 0;
      }

      &.bottom {
        bottom: -140px;
      }

      &.centerY {
        top: -140px;
      }

      &.active {
          &.left {
            left: -140px;
            opacity: 1;
          }

          &.right {
            right: -140px;
            opacity: 1;
          }

          &.experts {
            opacity: 0.56 !important;
          }
        }
    `}
  `;

export default class Circle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      className: props.className,
    };
  }

  componentDidMount() {
    const { active = true } = this.props;

    if (!active) {
      this.timeout = setTimeout(() => {
        this.onChange(true);
      }, 500);
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  onChange(visibility) {
    if (visibility && !this.state.visible) {
      this.setState({
        visible: true,
        className: `${this.state.className} active`,
      });
    }
  }

  render() {
    const { className } = this.state;
    const {
      offset = { bottom: -70 },
      partialVisibility = "bottom",
      active = true,
    } = this.props;

    return (
      <VisibilitySensor
        active={active}
        offset={offset}
        partialVisibility={partialVisibility}
        onChange={this.onChange.bind(this)}
      >
        <CircleElement className={className} />
      </VisibilitySensor>
    );
  }
}
